<template>
  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact">
    <div class="container">

      <div class="section-title">
        <h2>Contact</h2>
      </div>
    </div>

    <div>
      <iframe style="border:0; width: 100%; height: 350px;"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3183.611556470606!2d-93.2323413!3d37.066738900000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87cf706eaf72cccf%3A0xe4ed86215fc58d10!2s5006%20N%2022nd%20St%2C%20Ozark%2C%20MO%2065721!5e0!3m2!1sen!2sus!4v1718938547942!5m2!1sen!2sus"
        frameborder="0" allowfullscreen loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>

    <div class="container">

      <div class="row mt-5">

        <div class="col-lg-4">
          <div class="info">
            <div class="hours">
              <i class='bx bx-time'></i>
              <h4>Hours:</h4>
              <p>Mondays & Wednesdays by appointment only.</p>
              <p>(Other options may be available upon request)</p>
            </div>

            <div class="address">
              <i class='bx bx-map-pin'></i>
              <h4>Location:</h4>
              <p>5006 N 22nd St Ozark Mo, 65721</p>
              <p>(Located inside the 417 female)</p>
            </div>

            <div class="email">
              <i class='bx bx-envelope'></i>
              <h4>Email:</h4>
              <p>allie@chasingwellnesspt.com</p>
            </div>

            <div class="phone">
              <i class='bx bx-phone'></i>
              <h4>Call or Text:</h4>
              <p>417-233-4331</p>
            </div>

          </div>

        </div>

        <div class="col-lg-8 mt-5 mt-lg-0">
          <form method="post" role="form" novalidate class="email-form" id="email-form">
            <div class="row">
              <div class="col-md-6 form-group">
                <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required
                  pattern="/[A-Za-z]{1}[A-Za-z]/" data-pristine-pattern-message="Please tell us your name"
                  v-model="form.name">
              </div>
              <div class="col-md-6 form-group mt-3 mt-md-0">
                <input type="email" class="form-control" name="email" id="email" placeholder="Your Email"
                  v-model="form.email" required>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-8 mt-3">
                <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject"
                  v-model="form.subject" required>
              </div>
              <div class="form-group col-md-4 mt-3">
                <input type="text" class="form-control" name="phone" id="phone" placeholder="Phone #"
                  pattern="/[0-9]{10}/" data-pristine-pattern-message="Please enter only numbers" v-model="form.phone">
              </div>
            </div>
            <div class="form-group mt-3">
              <textarea class="form-control" name="message" id="message" rows="5" placeholder="Message"
                v-model="form.message" required></textarea>
            </div>
            
            <div class="row align-items-top justify-content-end mt-3">
              <div id="sent-message" class="form-group col-7 mr-auto sent-message">
                Your email has been delivered!
              </div>
              <div id="sent-error" class="form-group col-7 mr-auto error-message">
                We are having trouble delivering your message. Please try again!
              </div>
              <div class="form-group col-5 mr-auto">
                <div class="text-left float-end">
                  <button type="button" @click="submit">Send Message</button>
                </div>
              </div>
              
            </div>
          </form>
        </div>

      </div>

    </div>
  </section><!-- End Contact Section -->
</template>

<script>
import Pristine from 'pristinejs';
var form;
export default {

  mounted() {
    form = document.getElementById("email-form");
  },
  data() {
    return {
      form: {
        email: '',
        name: '',
        phone: '',
        subject: '',
        message: '',
        captcha_token: ''
      }
    }
  },
  methods: {
    async submit() {
      var pristine = new Pristine(form);
      if (pristine.validate()) {
        // (optional) Wait until recaptcha has been loaded.
        await this.$recaptchaLoaded()

        // Execute reCAPTCHA with action "login".
        this.form.captcha_token= await this.$recaptcha('contact')

          // Do stuff with the received token.
        var URL = "https://api.chasingwellnesspt.com/contact";
        var key = "CVCaLPirrwal9P3TIWfBWaMKxd7NJR6V8Sig4hNp";
        fetch(URL, {
          method: "POST",
          headers: {
            "X-Api-Key": key,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(this.form),
          withCredentials: true
        }).then((response) => {
          if(response.ok) {
            return response.json();
          }
          throw new Error("Failed sending email.");
        }).then(() => {
          document.getElementById("sent-error").style.display = "none";
          document.getElementById("sent-message").style.display = "block";
          form.reset();
        })
        .catch(() => {
          
        });
      }

    }
  }
}
</script>

<style lang="scss">
/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact {
  background: $white;

  .info {
    width: 100%;
    background: $white;

    i {
      font-size: 32px;
      color: $primary;
      float: left;
      line-height: 1;
    }

    h4 {
      padding: 0 0 0 60px;
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 5px;
      color: $secondary;
    }

    p {
      padding: 0 0 0 60px;
      margin-bottom: 0;
      font-size: 14px;
      color: lighten($secondary, 20);
    }

    .email,
    .phone,
    .address {
      margin-top: 40px;
    }
  }


  .email-form {
    width: 100%;
    background: #fff;

    .form-group {
      padding-bottom: 8px;

      &.has-danger {
        padding-bottom: 0px;

        .form-control {
          border-bottom: 2px solid #dc1d34;
        }
      }

      .text-help {
        color: #dc1d34;
        font-size: small;
        padding-left: 5px;
      }
    }

    #sent-error {
      display: none;
      color: #ed3c0d;
      text-align: center;
      padding: 10px 24px;
      font-weight: 600;

      & br+br {
        margin-top: 25px;
      }
    }

    #sent-message {
      display: none;
      color: #18d26e;
      text-align: center;
      padding: 10px 24px;
      font-weight: 600;
    }

    .loading {
      display: none;
      background: #fff;
      text-align: center;
      padding: 15px;

      &:before {
        content: "";
        display: inline-block;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        margin: 0 10px -6px 0;
        border: 3px solid #18d26e;
        border-top-color: #eee;
        animation: animate-loading 1s linear infinite;
      }

    }

    input,
    textarea {
      border-radius: 0;
      box-shadow: none;
      font-size: 14px;
      border-radius: 4px;

      &:focus {
        border-color: $primary;
      }
    }

    input {
      height: 44px;
    }

    textarea {
      padding: 10px 12px;
    }

    button {
      background: $primary;
      border: 0;
      padding: 10px 24px;
      color: #fff;
      transition: 0.4s;
      border-radius: 4px;

      &:hover {
        background: lighten($primary, 10%);
      }
    }

  }

  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
</style>