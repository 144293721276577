<template>
  <!-- ======= Header ======= -->
  <header id="header" class="fixed-top ">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-xl-9 d-flex align-items-center justify-content-lg-between">
          <a href="index.html" class="logo me-auto me-lg-0"><img src="../assets/img/header-logo-brown.png" alt=""
              class="img-fluid"></a>
          <a href="index.html" class="logo-white me-auto me-lg-0"><img src="../assets/img/header-logo-white.png" alt=""
              class="img-fluid"></a>
          <nav id="navbar" class="navbar order-last order-lg-0">
            <ul>
              <li><a class="nav-link scrollto active" href="#hero">Home</a></li>
              <li><a class="nav-link scrollto" href="#about">About</a></li>
              <li><a class="nav-link scrollto" href="#services">Services</a></li>
              <li><a class="nav-link scrollto" href="#pricing">Pricing</a></li>
              <li><a class="nav-link scrollto" href="#contact">Contact</a></li>
              <li><a href="https://chasingwellness.intakeq.com/booking" target="_blank" alt="Make an Online Appointment"
                  class="mobile-get-started-btn scrollto">Book Now</a></li>
            </ul>
            <span class="mobile-nav-toggle closed"><i class="bx bx-menu"></i></span>
          </nav><!-- .navbar -->

          <a href="https://chasingwellness.intakeq.com/booking" target="_blank" alt="Make an Online Appointment"
            class="get-started-btn scrollto">Book Now</a>
        </div>
      </div>
    </div>
  </header><!-- End Header -->
</template>
<style lang="scss">
/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/

/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  li {
    position: relative;
  }

  >ul>li {
    position: relative;
    white-space: nowrap;
    margin: 0 12px;
  }

  a,
  a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 3px;
    font-size: 14px;
    font-weight: 600;
    color: rgba($white, .7);
    white-space: nowrap;
    transition: 0.2s;
    position: relative;

    i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
    }

    &.mobile-get-started-btn {
      display: none;
      margin-left: 25px;
      background: $primary;
      color: $white;
      border-radius: 8px;
      padding: 12px 25px 12px 25px;
      white-space: nowrap;
      transition: 0.3s;
      font-size: 14px;

      &:hover {
        background: lighten($primary, 5);
        color: $white;
      }

      @media (max-width: 992px) {
        display: block;
      }
    }


  }

  >ul>li>a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: $primary;
    visibility: hidden;
    width: 0px;
    transition: all 0.3s ease-in-out 0s;
  }

  a:hover:before,
  li:hover>a:before,
  .active:before {
    visibility: visible;
    width: 100%;
  }

  .dropdown {

    ul {
      display: block;
      position: absolute;
      left: 0;
      top: calc(100% + 30px);
      margin: 0;
      padding: 10px 0;
      z-index: 99;
      opacity: 0;
      visibility: hidden;
      background: #fff;
      box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
      transition: 0.3s;
      border-top: 2px solid $primary;

      li {
        min-width: 200px;
      }

      a {
        padding: 10px 20px;
        font-size: 14px;
        text-transform: none;
        font-weight: 500;

        //color: $secondary;
        i {
          font-size: 12px;
        }
      }

      a:hover,
      .active:hover,
      li:hover>a {
        color: $primary;
      }

    }

    &:hover>ul {
      opacity: 1;
      top: 100%;
      visibility: visible;
    }

  }

  .dropdown .dropdown {

    ul {
      top: 0;
      left: calc(100% - 30px);
      visibility: hidden;
    }

    &:hover>ul {
      opacity: 1;
      top: 0;
      left: 100%;
      visibility: visible;
    }

    @media (max-width: 1366px) {
      ul {
        left: -90%;
      }

      &:hover>ul {
        left: -100%;
      }
    }
  }

}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 35px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.3s;
}




@media (max-width: 991px) {

  .mobile-nav-toggle {
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    
    i {
      font-size: 35px;
    }
  }
  .mobile-nav-toggle.closed::before {
    content: "MENU";
  }

  .mobile-nav-toggle.open::before {
    content: "CLOSE";
  }

  .navbar ul {
    display: none;
  }

  .mobile-get-started-btn {
    display: block;
  }

  .get-started-btn {
    display: none;
  }

}

.navbar-mobile {

  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(darken($secondary, 10), .9);
  transition: 0.3s;
  z-index: 999;

  
  .mobile-nav-toggle {
    position: absolute;
    top: 25px;
    right: 12px;
    color: white;
  }
  

  ul {
    display: block;
    position: absolute;
    top: 86px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }

  a,
  a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: $secondary;
  }

  a:hover:before,
  li:hover>a:before,
  .active:before {
    visibility: hidden;
  }

  a:hover,
  .active,
  li:hover>a {
    color: $primary;
  }

  .getstarted,
  .getstarted:focus {
    margin: 15px;
  }

  .dropdown {

    ul {
      position: static;
      display: none;
      margin: 10px 20px;
      padding: 10px 0;
      z-index: 99;
      opacity: 1;
      visibility: visible;
      background: #fff;
      box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);

      li {
        min-width: 200px;
      }

      a {
        padding: 10px 20px;

        i {
          font-size: 12px;
        }
      }

      a:hover,
      .active:hover,
      li:hover>a {
        color: $primary;
      }

    }

    >.dropdown-active {
      display: block;
    }

  }

}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  transition: all 0.2s;
  z-index: 997;
  border-bottom: 1px solid rgba($white, .1);

  &.header-scrolled,
  &.header-inner-pages {
    background: rgba($white, 1);
    border-bottom: 1px solid rgba($black, .4);
    color: rgba($black, .7);
  }

  &.header-scrolled a {
    color: rgba($black, .7);
  }

  &.header-scrolled i {
    color: rgba($black, .7);
  }

  &.header-scrolled .mobile-nav-toggle {
    color: rgba($black, .7);
  }

  &.header-scrolled .navbar-mobile .mobile-nav-toggle{
    color: $white;
    i {
      color: $white;
    }
  }

  .logo,
  .logo-white {
    font-size: 28px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 0.5px;

    a {
      color: #fff;
    }

    img {
      max-height: 55px;
    }
  }

  .logo {
    display: none;
  }

  &.header-scrolled .logo {
    display: block;
  }

  &.header-scrolled .logo-white {
    display: none;
  }


  @media (max-width: 992px) {
    padding: 15px 0;
  }
}


/* Get Startet Button */
.get-started-btn {
  margin-left: 25px;
  background: $primary;
  color: $white;
  border-radius: 8px;
  padding: 12px 25px 12px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  display: inline-block;

  &:hover {
    background: lighten($primary, 5);
    color: $white;
  }

  @media (max-width: 992px) {
    display: none;
  }

}
</style>