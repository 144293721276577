<template>
  <!-- ======= Services Section ======= -->
  <section id="services" class="services">
    <div class="container">

      <div class="section-title">
        <h2>Services</h2>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
          <div class="icon-box">
            <div class="icon"><i class="pticon-outpatient"></i></div>
            <h4>Manual Therapy</h4>
            <p>Hands on approach to reduce pain and improve mobility through soft tissue work and joint mobilization.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0 mt-lg-0">
          <div class="icon-box">
            <div class="icon"><i class="pticon-pain"></i></div>
            <h4>Headaches and migraines</h4>
            <p>Multifactorial approach to manage headaches and reduce frequency of migraines through a combination of
              education, exercise and manual therapy.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
          <div class="icon-box">
            <div class="icon"><i class="pticon-traumatism"></i></div>
            <h4>Pre/post operative care</h4>
            <p>Pre- or post-surgery your doctor may recommend physical therapy to help you improve strength, function
              and mobility.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
          <div class="icon-box">
            <div class="icon"><i class="pticon-walk_supported"></i></div>
            <h4>Balance and fall prevention</h4>
            <p>There are many factors that play a role in balance and fall prevention, treatment plans will vary based
              on assessment.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
          <div class="icon-box">
            <div class="icon"><i class="pticon-walking"></i></div>
            <h4>Injury Prevention</h4>
            <p>A great way to transition from physical therapy to ongoing strength and mobility work. If you are no
              longer experiencing pain, we can progress to general strength or activity specific injury reduction.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
          <div class="icon-box">
            <div class="icon"><i class="pticon-weights"></i></div>
            <h4>Wellness programming</h4>
            <p>Takes into account the whole you! A mind, body, spirit approach to healthy living with emphasis on
              anti-inflammatory lifestyle choices.</p>
          </div>
        </div>
      </div>
    </div>
  </section><!-- End Services Section -->
</template>
<style lang="scss">
/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services {

  .icon-box {
    padding: 60px 30px;
    transition: all ease-in-out 0.3s;
    background: #fefefe;
    box-shadow: 0px 5px 90px 0px rgba(110, 123, 131, 0.1);
    border-radius: 18px;
    border-bottom: 5px solid $white;
    width: 100%;

    .icon {
      width: 64px;
      height: 64px;
      background: $primary;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      transition: ease-in-out 0.3s;
      color: $white;

      i {
        font-size: 32px;
      }

    }

    h4 {
      font-weight: 700;
      margin-bottom: 15px;
      font-size: 24px;
      color: $secondary;
      transition: ease-in-out 0.3s;
    }

    p {
      line-height: 24px;
      font-size: 14px;
      color: $secondary;
      margin-bottom: 0;
    }

    &:hover {
      transform: translateY(-10px);
      border-color: $primary;

      h4 {
        color: $primary;
      }
    }
  }
}
</style>