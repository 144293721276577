<template>
  <!-- ======= Faq Section ======= -->
  <section id="faq" class="faq">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
          <div class="content">
            <h3>Frequently Asked <strong>Questions</strong></h3>
          </div>

          <div class="accordion-list">
            <ul>
              <li>
                <a data-bs-toggle="collapse" class="collapse" data-bs-target="#accordion-list-1">Do you take insurance?
                  <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                <div id="accordion-list-1" class="collapse show" data-bs-parent=".accordion-list">
                  <p>
                    We are a private-pay practice that does not accept insurance. However, you can submit to your
                    insurance provider to see if they will cover a portion of your services and reimburse you directly,
                    and many often do. Upon request, we can provide invoices and notes for you to submit to your
                    insurance company.
                  </p>
                  <p>
                    For most insurance companies, you can find a claim form on their website. Simply print it out and
                    submit it along with your invoices and treatment codes that we can provide for you. The amount you
                    are reimbursed is dependent on your “out-of-network physical therapy” expense benefits.
                  </p>
                </div>
              </li>

              <li>
                <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2" class="collapsed">Why cash-based
                  physical therapy? <i class="bx bx-chevron-down icon-show"></i><i
                    class="bx bx-chevron-up icon-close"></i></a>
                <div id="accordion-list-2" class="collapse" data-bs-parent=".accordion-list">
                  <p> 60 min treatment sessions with 1 on 1 care <br>
                    You will always know what a session cost (no surprises on your bill) <br>
                    Your insurance company may reimburse you with our provided superbills <br>
                    In hospital-based settings, therapy can cost between $300-$500 per session

                  </p>
                </div>
              </li>

              <li>
                <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3" class="collapsed">Do you accept HSA/FSA
                  benefits? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                <div id="accordion-list-3" class="collapse" data-bs-parent=".accordion-list">
                  <p>
                    Yes! Let Allie know that you would like to use your HSA/FSA, and we can help you with this process.
                  </p>
                </div>
              </li>

              <li>
                <a data-bs-toggle="collapse" data-bs-target="#accordion-list-4" class="collapsed">Do I need a referral
                  from my doctor? <i class="bx bx-chevron-down icon-show"></i><i
                    class="bx bx-chevron-up icon-close"></i></a>
                <div id="accordion-list-4" class="collapse" data-bs-parent=".accordion-list">
                  <p>
                    No! Missouri has direct access to physical therapy services as of August 2023! What does this mean?
                    No more waiting for physician referrals. With direct access, you can skip the hassle and jump right
                    into a personalized rehab plan and get relief to hit your goals faster.
                  </p>
                  <p>
                    Saves you time; it can take weeks or even months to get in to see your doctor. Prompt intervention
                    after an injury is key to long-term positive outcomes, and direct access helps with that!
                  </p>
                  <p>
                    Saves you money; No need to go to your primary care provider where they refer you to see a physical
                    therapist anyway! Bye-bye unnecessary co-pays. In addition, no hidden fees/costs you know exactly
                    what each visit will cost ahead of time and not be surprised by your bill later!
                  </p>
                  <p>
                    Prevents future problems; Stay ahead of issues! Direct access lets you address minor concerns before
                    they become major barriers in your life! It also allows the PT to address your body as a whole
                    instead of just one area written on a doctor’s referral.
                  </p>
                </div>
              </li>

            </ul>
          </div>

        </div>

        <div class="col-lg-5  order-1 order-lg-2 img">&nbsp;</div>
      </div>

    </div>
  </section>
  <!-- End Faq Section -->
</template>
<style lang="scss">
/*--------------------------------------------------------------
# Faq
--------------------------------------------------------------*/
.faq {
  padding: 0;

  .content {
    padding: 60px 100px 0 100px;

    h3 {
      font-weight: 400;
      font-size: 34px;
    }

    h4 {
      font-size: 20px;
      font-weight: 700;
      margin-top: 5px;
    }

    p {
      font-size: 15px;
      color: lighten($default, 25);
    }
  }

  .img {
    background-size: cover;
    background-image: url("../../assets/img/faq.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 400px;
  }

  .accordion-list {
    padding: 0 100px 60px 100px;

    ul {
      padding: 0;
      list-style: none;
    }

    li+li {
      margin-top: 15px;
    }

    li {
      padding: 20px;
      background: #fefefe;
      box-shadow: 0px 5px 90px 0px rgba(110, 123, 131, 0.1);
      border-radius: 4px;
    }

    a {
      display: block;
      position: relative;
      font-family: $font-secondary;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      padding-right: 30px;
      outline: none;
      cursor: pointer;
    }

    i {
      font-size: 24px;
      position: absolute;
      right: 0;
      top: 0;
    }

    p {
      margin-bottom: 0;
      padding: 10px 0 0 0;
      color: $secondary;
    }

    .icon-show {
      display: none;
    }


    a.collapsed {
      color: $black;

      &:hover {
        color: $primary;
      }

      .icon-show {
        display: inline-block;
      }

      .icon-close {
        display: none;
      }
    }
  }

  @media (max-width: 1024px) {

    .content,
    .accordion-list {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media (max-width: 992px) {

    /* img {
      padding-top: 30px;
    } */
    .content {
      padding-top: 30px;
    }

    .accordion-list {
      padding-bottom: 30px;
    }
  }
}
</style>