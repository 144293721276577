<template>
  <!-- ======= Pricing Section ======= -->
  <section id="pricing" class="pricing">
    <div class="container">
      <div class="section-title">
        <h2>Pricing</h2>
        <p>
          Cash based physical therapy services mean that we do not take
          insurance. We can provide a superbill with proper coding upon request.
        </p>
      </div>

      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
          <div class="box">
            <h3>Initial Evaluation</h3>
            <h4><sup>$</sup>195</h4>
            <ul>
              <li>60 min evaluation</li>
              <li>Discussion of history</li>
              <li>Education on pain generators</li>
              <li>Physical exam</li>
              <li>Home exercise programming</li>
            </ul>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 mt-4 mt-md-0">
          <div class="box recommended">
            <h3>Follow up</h3>
            <h4><sup>$</sup>175</h4>
            <ul>
              <li>45-60 minute sessions</li>
              <li>Reassessment</li>
              <li>Manual therapy </li>
              <li>Progression of exercise </li>
              <li>Recommend scheduling 4-6 follow up sessions to guarantee visits</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="section-footer">
        <p>
          ** Cancellation policy : Please provide 24 hours notice for changes to 
          follow-up sessions or $100 rescheduling/cancellation fee will apply. 
          No shows/same day cancellations are full cost of session. 
          Thank you for your understanding! **
        </p>
      </div>
    </div>
  </section>
  <!-- End Pricing Section -->
</template>
<style lang="scss">
/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing {
  background-color: lighten($secondary, 82);

  p {
    color: #212121;
  }

  
  .box {
    padding: 40px 20px;
    text-align: center;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    background: #fefefe;
    box-shadow: 0px 5px 90px 0px rgba(110, 123, 131, 0.1);
    height: 100%;

    h3 {
      font-weight: 400;
      padding: 15px;
      margin-top: 15px;
      font-size: 18px;
      font-weight: 600;
      color: $secondary;
    }

    h4 {
      font-size: 42px;
      color: $primary;
      font-weight: 500;
      font-family: $font-default;
      margin-bottom: 20px;

      sup {
        font-size: 20px;
        top: -15px;
        left: -3px;
      }

      span {
        color: #bababa;
        font-size: 16px;
        font-weight: 300;
      }
    }

    ul {
      padding: 20px 0;
      list-style: none;
      color: $secondary;
      text-align: center;
      line-height: 20px;
      font-size: 14px;

      li {
        padding-bottom: 16px;
      }

      i {
        color: $primary;
        font-size: 18px;
        padding-right: 4px;
      }

      .na {
        color: #ccc;
        text-decoration: line-through;
      }
    }

    .btn-wrap {
      padding: 15px;
      text-align: center;
    }

    .btn-buy {
      display: inline-block;
      padding: 10px 40px 12px 40px;
      border-radius: 50px;
      border: 2px solid $primary;
      color: $primary;
      font-size: 14px;
      font-weight: 400;
      font-family: $font-primary;
      font-weight: 600;
      transition: 0.3s;

      &:hover {
        background: $primary;
        color: $white;
      }
    }
  }

  .recommended {
    border-color: $primary;

    .btn-buy {
      background: $primary;
      color: #fff;

      &:hover {
        background: lighten($primary, 5);
        border-color: lighten($primary, 5);
      }
    }
  }

  .recommended-badge {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    font-size: 13px;
    padding: 3px 25px 6px 25px;
    background: lighten($green, 55);
    color: $primary;
    border-radius: 50px;
  }
}
</style>
