<template>
  <!-- ======= Cta Section ======= -->
  <section id="cta" class="cta">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h3>Wellness isn’t a destination it’s something you are always going after!</h3>
        </div>
        <div class="cta-btn-container text-center">
          <a class="cta-btn align-middle" href="https://chasingwellness.intakeq.com/booking">Book an appointment!</a>
        </div>
      </div>
    </div>
  </section><!-- End Cta Section -->
</template>
<style lang="scss">
/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
  background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, .6)), url("../../assets/img/cta-bg.jpg") fixed center center;
  background-size: cover;
  padding: 120px 0;

  h3 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
  }

  p {
    color: #fff;
    font-size: 12px;
  }

  .cta-btn {
    font-family: $font-primary;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 30px;
    border-radius: 8px;
    transition: 0.5s;
    margin: 10px;
    background: $primary;
    color: #fff;

    &:hover {
      background: lighten($primary, 5);
    }
  }

  @media (max-width: 1024px) {
    background-attachment: scroll;
  }

  @media (min-width: 769px) {
    .cta-btn-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>