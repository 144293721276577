<template>
  <!-- ======= About Us Section ======= -->
  <section id="about" class="about">
    <div class="container">

      <div class="section-title">
        <h2>Mission</h2>
        <p>At Chasing Wellness Physical Therapy my mission is to create a unique experience to address your whole-body
          wellness for optimal results on your healing journey. Being a private cash pay clinic you will receive 1:1
          care personalized to you and your goals.</p>
      </div>

      <div class="row content">
        <h3>Meet Allie!</h3>
        <div class="col-lg-7">
          <p>
            Hi! My name is Allie Haas. I am a physical therapist in Springfield, MO area. I received my Doctorate of
            Physical Therapy from Missouri State University in 2018. I am passionate about treating spinal pain, chronic
            headaches/migraines, orthopedic injuries, injury prevention and love all things holistic wellness!
          </p>
          <p>
            I discovered my love for manual physical therapy and treating spinal spine after working with incredible
            leaders in the manual therapy world in Kansas City! I loved 1 on 1 patient care, watching how manual therapy
            can instantly change patients pain levels and how healing through movement and proper programming changed
            lives!
          </p>
          <p>
            Like anything in life you can create good habits for a few weeks to months to achieve a desired goal. You
            can also go to physical therapy for 6 weeks to reach a desired goal but then what? What about a tune up?
            What about prevention? What about the mind/body connection? What about when something comes up or doesn’t go
            according to plan?
          </p>
          <p>
            Chasing Wellness Physical Therapy was birthed from that. Wellness isn’t a destination it’s something you are
            always going after! I’m excited to bring my passion for healthy living, helping people and my knowledge
            about the spine and wellness to you!!
          </p>
        </div>
        <div class="col-lg-5 pt-4 pt-lg-0">
          <img src="../../assets/img/allie.jpg" alt="" class="img-fluid">
        </div>
      </div>

    </div>
  </section><!-- End About Us Section -->
</template>
<style lang="scss">
/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about {
  .content {
    h3 {
      font-weight: 600;
      font-size: 26px;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        padding-left: 28px;
        position: relative;
      }

      li+li {
        margin-top: 10px;
      }

      i {
        position: absolute;
        left: 0;
        top: 2px;
        font-size: 20px;
        color: $primary;
        line-height: 1;
      }
    }

    p {
      color: #212121;
    }

    p:last-child {
      margin-bottom: 0;
    }

    .btn-learn-more {
      font-family: $font-primary;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 1px;
      display: inline-block;
      padding: 12px 32px;
      border-radius: 50px;
      transition: 0.3s;
      line-height: 1;
      color: $primary;
      animation-delay: 0.8s;
      margin-top: 6px;
      border: 2px solid $primary;

      &:hover {
        background: $primary;
        color: #fff;
        text-decoration: none;
      }
    }
  }
}
</style>